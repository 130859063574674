import React from 'react'

const CirclesMoving = () => {
    return (
        <section>
            <div className='circle-blur animate-y w-[500px] h-[500px]  top-[300px] -left-[300px]' />
            <div className='circle-blur animate-y w-[300px] h-[300px]  top-[100px] -right-[200px]' />
        </section>
    )
}

export default CirclesMoving