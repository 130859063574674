import { initReactI18next } from "react-i18next";
import i18 from "i18next";
import esLang from "./assets/lang/es.json";
import enLang from "./assets/lang/en.json";

i18.use(initReactI18next).init({
  resources: {
    en: {
      translation: enLang,
    },
    es: {
      translation: esLang,
    },
  },
  lng: "en", // if you're using a language detector, do not define the lng option
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});
