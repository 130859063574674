import React, { useState, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination, Scrollbar, Navigation } from "swiper";
import { ITestimonial } from "../types/testimonials.interface";
import imageUrlBuilder from "@sanity/image-url";
import { sanityClient } from "../sanity-client";

const Testimonials = ({ isEn }: { isEn: boolean }) => {
  const [testimonials, setTestimonials] = useState<ITestimonial[]>([]);

  const getImage = (url: string) => {
    if (!url) return require("../assets/png/isotipo.png");
    const builder = imageUrlBuilder(sanityClient);
    return builder.image(url);
  };

  //effects
  useEffect(() => {
    (async () => getData())();
  }, []);

  //methods
  const getData = async () => {
    try {
      //sanity query
      const data = await sanityClient.fetch(
        `*[_type == "testimonials"]{
                _id,
                name,
                photo,
                description,
                descriptionEn
            }`
      );

      setTestimonials(data as ITestimonial[]);
    } catch (error) {}
  };

  return (
    <section className=" bg-purple/30 w-full py-10 relative">
      <div className="containerr flex flex-wrap justify-between gap-x-10 lg:divide-x divide-white/20">
        <Swiper
          modules={[Autoplay, Navigation, Pagination, Scrollbar]}
          spaceBetween={50}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 18,
            },
            600: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          scrollbar={{ draggable: true }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          // navigation={true}
          loop={true}
          className="mySwiper"
        >
          {testimonials.map((item) => {
            const {
              _id,
              name,
              photo: image,
              description,
              descriptionEn,
            } = item;
            return (
              <SwiperSlide key={_id}>
                <div className="w-full  items-center md:items-start flex flex-col text-center md:text-start md:w-fit mb-12">
                  <div className="flex items-center gap-x-3">
                    <img
                      className=" object-cover rounded-full w-[50px] h-[50px]"
                      src={getImage(image).url()}
                      alt={name}
                    />
                    <div>
                      <h3>{name}</h3>
                      <Star />
                    </div>
                  </div>
                  <div className="group cursor-pointer mt-3 max-w-[90%]">
                    <p className="line-clamp-6">
                      {isEn ? descriptionEn : description}
                    </p>
                    <span className=" opacity-0 group-hover:opacity-100 transition-all duration-300 flex text-xs rounded-lg bg-dark mb-10 p-5 text-white absolute inset-0  justify-center items-center ">
                      {isEn ? descriptionEn : description}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;

const Star = () => {
  return (
    <div className=" -translate-x-[2px] text-yellow-500 flex items-center">
      <AiFillStar />
      <AiFillStar />
      <AiFillStar />
      <AiFillStar />
      <AiFillStar />
    </div>
  );
};
