import React, { createContext, useEffect, useMemo, useState } from "react";
import { IText } from "../types/text.interface";
import { sanityClient } from "../sanity-client";

export type Props = {
  values: IText[];
  getValue: (_: string) => string;
};

export const ContextProvider = createContext<Props>({
  getValue: (_: string) => "",
  values: [],
});

export const AppContext: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  //state
  const [values, setValues] = useState<IText[]>([]);

  //effects
  useEffect(() => {
    (async () => getValues())();
  }, []);

  const getValues = async () => {
    //sanity query
    const data = await sanityClient.fetch(
      `*[_type == "textValues"]{
                _id,
                key,
                value,
                valueEn
            }`
    );

    setValues(data);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getValue = (key: string) => {
    const value = values.find((v) => v.key === key);
    return value?.value || "";
  };

  const appStateProvider = useMemo(
    () => ({ values, getValue }),
    [values, getValue]
  );

  return (
    <ContextProvider.Provider value={appStateProvider}>
      {children}
    </ContextProvider.Provider>
  );
};
