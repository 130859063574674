import React from "react";

const Modal = ({ setOpen, open, videoLink }: any) => {
  if (open) {
    return (
      <div className=" fixed inset-0 z-50  flex items-center justify-center h-screen w-[100vw]">
        <div
          onClick={() => setOpen(!open)}
          className=" bg-black/80 inset-0 absolute"
        />
        <div className="relative z-50">
          <iframe
            width="1200"
            height="1000"
            src={videoLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    );
  }
  return null;
};

export default Modal;
