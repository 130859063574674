import { IDevProject } from "../interfaces/dev-project.interface";
import { sanityClient } from "../sanity-client";

const projectQuery = `
    *[_type == "devsproject"] {
        _id,
        title,
        titleEn,
        date,
        route,
        tags,
        image,
        description,
        descriptionEn,
        bgColor
    }
`;

export default class DevProjectService {
  async getProjects() {
    const result = await sanityClient.fetch<IDevProject[]>(projectQuery);
    console.log(result);
    let results = result.map((project) => {
      return {
        ...project,
        date: new Date(project.date),
      };
    });

    return results.sort((a, b) => {
      return b.date.getTime() - a.date.getTime();
    });
  }
}
