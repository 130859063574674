import React from "react";
import { sanityClient } from "../sanity-client";
import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source: SanityImageSource) {
  return builder.image(source);
}

export const SanityImage: React.FC<{
  image: any;
  width?: number;
  height?: number;
  className?: string;
}> = ({ image, width = 1000, height = 1000, className = "" }) => {
  return (
    <img
      className={className}
      src={urlFor(image).width(width).height(height).url()}
      alt="sanity.png"
    />
  );
};
