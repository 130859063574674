import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { sanityClient } from "../sanity-client";
import { IProject } from "../types/project.interface";
import { buildImage } from "./Services";
import { useTranslation } from "react-i18next";

const Projects = ({ isEn }: { isEn: boolean }) => {
  //state
  const [projects, setProjects] = useState<IProject[]>([]);

  //effects

  useEffect(() => {
    (async () => getProjects())();
  }, []);

  //methods
  const getProjects = async () => {
    const query = `*[_type == "projects"]{
      _id,
      title,
      titleEn,
      description,
      descriptionEn,
      Imagen,
      path
    }`;

    const response = await sanityClient.fetch(query);

    setProjects(response);
  };

  return (
    <section className=" containerr mt-16">
      {projects.map((project, index) => (
        <ProjectsItem isEn={isEn} key={project.title} project={project} />
      ))}
    </section>
  );
};

export default Projects;

const ProjectsItem: React.FC<{ project: IProject; isEn: boolean }> = ({
  project,
  isEn,
}) => {
  const { t } = useTranslation("translation");
  return (
    <div
      className={`${
        project.title === "Proyectos realizados con VR"
          ? "md:flex-row-reverse"
          : "md:flex-row"
      } my-28 flex flex-col  items-center justify-between gap-12`}
    >
      <img
        src={buildImage(project.Imagen).url()}
        alt={project.Imagen}
        style={{
          borderRadius: "100px",
        }}
        className="w-96 object-cover animate-pulse"
      />
      <div className="flex flex-col text-center lg:text-start">
        <h1 className="text-3xl font-bold mb-5">
          {isEn ? project.titleEn : project.title}
        </h1>
        <p className="font-light max-w-lg">
          {isEn ? project.descriptionEn : project.description}
        </p>
        <Link to={project.path}>
          <button className="btn w-fit mt-5">{t("more")}</button>
        </Link>
      </div>
    </div>
  );
};
