import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import imageUrlBuilder from "@sanity/image-url";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Scrollbar, Navigation } from "swiper";
import { IResource } from "../types/resource.interface";
import { sanityClient } from "../sanity-client";
import Modal from "./Modal";
import { FaPlay } from "react-icons/fa";

type Props = {
  title: string;
  description: string;
  videos: IResource[];
  client: string;
  testimonial: string;
};

const ProjectComponent: React.FC<Props> = ({
  title,
  description,
  videos,
  client,
  testimonial,
}) => {
  const [video, setVideo] = React.useState<string>("");
  const [isHover, setIsHover] = React.useState(false);

  const getImage = (url: string) => {
    if (!url) return require("../assets/png/vr.png");
    const builder = imageUrlBuilder(sanityClient);
    return builder.image(url);
  };

  return (
    <div className=" h-screen text-white containerr mt-20 text-center items-center flex flex-col gap-y-6">
      <h1>{title}</h1>
      <p className=" max-w-[80ch]">{description}</p>
      <div className=" relative overflow-hidden mt-8 w-full aspect-video flex bg-black/50 rounded-3xl border-purple border-2">
        <Swiper
          modules={[Autoplay, Navigation, Pagination, Scrollbar]}
          effect="fade"
          speed={1000}
          slidesPerView={1}
          loop={true}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          scrollbar={{ draggable: true }}
          className="mySwiper"
        >
          {video && (
            <Modal
              videoLink={video}
              setOpen={() => {
                setVideo("");
              }}
              open={video.length}
            />
          )}
          {videos.map((item) => {
            return (
              <SwiperSlide
                style={{
                  position: "relative",
                  textAlign: "center",
                }}
                key={item._id}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
              >
                <img
                  className="w-full h-full inset-0 object-cover "
                  src={getImage(item.img).url()}
                  alt={item.img}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {isHover && (
                    <span
                      style={{ cursor: "pointer" }}
                      className="flex h-10 w-10 relative"
                      onClick={() => setVideo(item.path)}
                    >
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink opacity-75"></span>
                      <span className="grid place-content-center rounded-full h-10 w-10 bg-pink">
                        <FaPlay className="pl-[1px]" />
                      </span>
                    </span>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default ProjectComponent;
