import React, { useState, useEffect } from "react";
// import icons

import { IService } from "../types/services.interface";
import { sanityClient } from "../sanity-client";
import imageUrlBuilder from "@sanity/image-url";

export const buildImage = (image: string) => {
  return imageUrlBuilder(sanityClient).image(image);
};

const Services = ({ isEn }: { isEn: boolean }) => {
  //state
  const [services, setServices] = useState<IService[]>([]);
  //effects

  useEffect(() => {
    (async () => getServices())();
  }, []);

  //methods
  const getServices = async () => {
    const data = await sanityClient.fetch(
      `*[_type == "HeroCategory"]{
                _id,
                title,
                titleEn,
                photo,
                description,
                descriptionEn
            }`
    );
    setServices(data);
  };

  return (
    <section className=" bg-purple/30 w-full">
      <div className="containerr grid md:grid-cols-1 lg:grid-cols-3 lg:[&>*]:border-r [&>*]:border-white/20 [&>*:last-child]:border-none ">
        {services.map((service) => {
          return (
            <div
              className=" text-center px-10 my-10  mx-auto"
              key={service._id}
            >
              <img
                className="  h-20 mx-auto"
                src={buildImage(service.photo).url()}
                alt={service.title}
              />
              <h2 className=" text-xl font-bold mt-8 text-white">
                {isEn ? service.titleEn : service.title}
              </h2>
              <p className="mt-3 max-w-[50ch]">
                {isEn ? service.descriptionEn : service.description}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Services;
