import React, {  useState } from "react";
import { FaPlay } from "react-icons/fa";
import Illustration from "../assets/svg/illustration.svg";
import Illustration3 from "../assets/svg/illustration-3.svg";
import Modal from "./Modal";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const Hero: React.FC<{ videoLink: string }> = ({ videoLink }) => {
  //context
  //context

  const [open, setOpen] = useState(false);

  const { t } = useTranslation("translation");

  return (
    <>
      <Swiper
        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView={1}
        autoplay={{
          delay: 6000,
        }}
        loop={true}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        className="mySwiper h-[90vh] overflow-hidden relative"
      >
        <SwiperSlide>
          <section className="flex pb-20 flex-col lg:flex-row justify-between items-center containerr mt-24">
            {videoLink && (
              <Modal videoLink={videoLink} setOpen={setOpen} open={open} />
            )}
            <div className="flex flex-col gap-y-4">
              <h1 className="max-w-[16ch]">{t("pages.home.hero1Title")}</h1>
              <p className=" text-center lg:text-start max-w-[60ch]">
                {t("pages.home.hero1Subtitle")}
              </p>
              <div className="mt-3 flex items-center gap-x-8 mx-auto lg:mx-0">
                <div
                  onClick={() => setOpen(!open)}
                  className="flex items-center gap-x-3 cursor-pointer"
                >
                  <span className="flex h-10 w-10 relative">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink opacity-75"></span>
                    <span className="grid place-content-center rounded-full h-10 w-10 bg-pink">
                      <FaPlay className=" pl-[2px]" />
                    </span>
                  </span>
                  <span>Trailer</span>
                </div>
              </div>
            </div>
            <img className=" w-[50%] mt-16 lg:mt-0" src={Illustration} alt="" />
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className="flex pb-20 flex-col lg:flex-row justify-between items-center containerr mt-24">
            {videoLink && (
              <Modal videoLink={videoLink} setOpen={setOpen} open={open} />
            )}
            <div className="flex flex-col gap-y-4">
              <h1 className="max-w-[16ch]">{t("pages.home.hero2Title")}</h1>
              <p className=" text-center lg:text-start max-w-[60ch]">
                {t("pages.home.hero2Subtitle")}
              </p>
              <div className="mt-3 flex items-center gap-x-8 mx-auto lg:mx-0">
                <Link
                  to="/our-works"
                  className="flex items-center gap-x-3 cursor-pointer"
                >
                  <span className="flex h-10 w-10 relative">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink opacity-75"></span>
                    <span className="grid place-content-center rounded-full h-10 w-10 bg-pink">
                      <AiFillEye className=" pl-[2px] text-3xl" />
                    </span>
                  </span>
                  <span>{t("viewProject")}</span>
                </Link>
              </div>
            </div>
            <img
              className=" w-[50%] mt-16 lg:mt-0"
              src={Illustration3}
              alt=""
            />
          </section>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Hero;
