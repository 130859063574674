import React from "react";
import Isotipo from "../assets/png/isotipo.png";

// import icons
import { ImInstagram } from "react-icons/im";
import { GrYoutube } from "react-icons/gr";
import Copyright from "./Copyright";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <section>
      <div className="py-14 flex justify-between flex-col md:flex-row gap-20 items-center text-center md:text-start md:items-start containerr">
        <div className="flex flex-col gap-y-3 flex-1 items-center md:items-start">
          <img className="w-[80px]" src={Isotipo} alt="isotipo" />
          <p className=" text-xs">{t("slogan")}</p>
          {/* social medias */}
          <div className="flex gap-x-2 text-xl text-pink ">
            <IconItem>
              <ImInstagram
                onClick={() => {
                  window.open("https://www.instagram.com/tenseird/", "_blank");
                }}
              />
            </IconItem>

            <IconItem>
              <GrYoutube
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/@mateofloyd5263/videos",
                    "_blank"
                  );
                }}
              />
            </IconItem>
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <h2 className=" text-2xl mb-3 whitespace-nowrap">
            {t("menu.aboutUs")}
          </h2>
          <p>
            <a href={"/about"}> {t("menu.aboutUs")}</a>
          </p>
        </div>
        <div className="flex flex-col gap-y-2">
          <h2 className=" text-2xl mb-3 whitespace-nowrap">
            {t("footer.services")}
          </h2>
          <a href={"/projects/VR"}>{t("footer.service1")}</a>
          <a href={"/projects/AR"}>{t("footer.service2")}</a>
          <a href={"/projects/Gamification"}>{t("footer.service3")}</a>
        </div>
        <div className="flex flex-col gap-y-2">
          <h2 className=" text-2xl mb-3">{t("menu.contact")}</h2>
          <p>tenseird@gmail.com</p>
        </div>
      </div>
      <Copyright />
    </section>
  );
};

export default Footer;

const IconItem = ({ children }: any) => {
  return (
    <div className="hover:bg-blue cursor-pointer text-white bg-pink w-[30px] h-[30px] rounded-full grid place-content-center">
      {children}
    </div>
  );
};
