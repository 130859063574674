import React from 'react'

const Copyright = () => {
    return (
        <section className=' border-t border-gray-500 py-5 containerr gap-x-10 text-xs flex justify-between'>
            <span className='flex-1'>
                © 2023 Tensei. All rights reserved.
            </span>
        </section>
    )
}

export default Copyright