import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation("translation");
  const Info = [
    {
      title: t("pages.aboutUs.mission"),
      description: t("pages.aboutUs.missionBody"),
    },
    {
      title: t("pages.aboutUs.client"),
      description: t("pages.aboutUs.clientBody"),
    },
    {
      title: t("pages.aboutUs.engagement"),
      description: t("pages.aboutUs.engagementBody"),
    },
    {
      title: t("pages.aboutUs.conclusion"),
      description: t("pages.aboutUs.conclusionBody"),
    },
  ];
  return (
    <div className="  text-white containerr mt-20  gap-y-6">
      <main className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <h1 className="text-6xl text-white font-bold mb-14 text-center mx-auto">
            {t("pages.aboutUs.title")}{" "}
          </h1>
        </div>
        <div className="bg-dark text-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium">
              {t("pages.aboutUs.intro")}{" "}
            </h3>
            <p className="mt-1 max-w-2xl text-sm">
              {t("pages.aboutUs.introBody")}{" "}
            </p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              {Info.map((item, index) => {
                return (
                  <div className="bg-dark px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      {item.title}
                    </dt>
                    <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2">
                      {item.description}
                    </dd>
                  </div>
                );
              })}
            </dl>
          </div>
        </div>
      </main>
    </div>
  );
};

export default About;
