import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProjectComponent from "../components/ProjectComponent";
import { IResource } from "../types/resource.interface";
import { useTranslation } from "react-i18next";
import { sanityClient } from "../sanity-client";

const Project = () => {
  //state
  const [resources, setResources] = useState<IResource[]>([]);
  const [resource, setResource] = useState<IResource>();
  const { i18n } = useTranslation("translation");
  const isEn = i18n.language === "en";

  //router
  const { type } = useParams<{ type: string }>();

  //effects

  useEffect(() => {
    (async () => getAll())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  //methods
  const get = async () => {
    const query = `*[_type == "resource"]{
      _id,
      title,
      titleEn,
      description,
      descriptionEn,
      type,
      path,
      img
    }`;

    const response = await sanityClient.fetch(query);

    return response as IResource[];
  };

  //methods
  const getAll = async () => {
    const path = process.env.REACT_APP_RESOURCES_URL;
    if (!path) return;
    try {
      const data = await get();

      const filtered = data.filter(
        (item) => item.type.trim().toUpperCase() === type?.toUpperCase()
      );

      const resource = filtered.find(
        (item) => item.title !== "" && item.description !== ""
      );
      if (resource) setResource(resource);

      setResources(filtered);
    } catch (error) {
      alert("ha ocurrido un error, intente de nuevo mas tarde");
      console.log(error);
    }
  };

  return (
    <div>
      <ProjectComponent
        title={(isEn ? resource?.titleEn : resource?.title) || ""}
        description={
          (isEn ? resource?.descriptionEn : resource?.description) || ""
        }
        videos={resources}
        client="Client Name"
        testimonial={`
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Sed euismod, nisl nec tincidunt lacinia, nisl nisl aliquam 
        ipsum, nec ultrices nunc nisl eget nisl. Sed euismod, nisl.
      `}
      />
    </div>
  );
};

export default Project;
