import React, { useEffect } from "react";
import { motion } from "framer-motion";

// import components

// import icons
import { BsArrowDownCircle } from "react-icons/bs";
import { Tilt } from "react-tilt";
import Title from "../components/Title";
import { fadeIn } from "../varients";
import { IDevProject } from "../interfaces/dev-project.interface";
import { SanityImage } from "../components/SanityImage";
import DevProjectService from "../services/dev-project.service";
import { useTranslation } from "react-i18next";

const OurWorks = () => {
  //hooks
  const [projects, setProjects] = React.useState<IDevProject[]>([]);
  const { t, i18n } = useTranslation("translation");
  const isEn = i18n.language === "en";

  //effects
  useEffect(() => {
    (async () => await getAll())();
  }, []);

  //methods
  const getAll = async () => {
    try {
      const service = new DevProjectService();
      const response = await service.getProjects();
      setProjects(response);
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  };

  return (
    <section className="container max-w-4xl mx-auto mt-32 px-4">
      <div className="flex gap-x-4 items-center">
        <h1>
          <Title text={t("menu.ourWork")} />
        </h1>
        <BsArrowDownCircle className="text-2xl text-primary mt-4 cursor-pointer" />
      </div>
      <div className="flex flex-col gap-y-10 mt-10 max-w-4xl">
        {projects.map((project) => {
          return (
            <Tilt
              options={{
                max: 10,
                scale: 1.01,
                speed: 1000,
                transition: true,
                axis: null,
                reset: true,
              }}
              key={project._id}
            >
              <div
                className=" group relative rounded-xl overflow-hidden cursor-pointer w-fullp-10 h-[700px] md:h-[500px] p-10"
                style={{ backgroundColor: project.bgColor }}
              >
                <a
                  target="noopener"
                  href={project.route}
                  className="flex justify-start md:justify-end items-end md:items-center h-full"
                >
                  <motion.div
                    variants={fadeIn("down", 1)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0 }}
                    className="h-[400px] w-[400px]"
                  >
                    <SanityImage image={project.image} />
                  </motion.div>
                  <div className="flex absolute left-10 top-10 md:top-auto flex-col justify-start  md:justify-center h-full">
                    <div className="duration-500 text-white p-2 rounded-md flex flex-col gap-y-5">
                      <motion.div
                        variants={fadeIn("down", 1)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0 }}
                      >
                        <Title
                          text={isEn ? project.titleEn : project.title}
                          className="text-4xl"
                        />
                      </motion.div>
                      <motion.p
                        variants={fadeIn("down", 2)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.9 }}
                        className=" max-w-[50ch]"
                      >
                        {isEn ? project.descriptionEn : project.description}
                      </motion.p>
                      <motion.div
                        variants={fadeIn("down", 3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.9 }}
                        className="hidden md:flex"
                      >
                        {tagsComponent(project.tags)}
                      </motion.div>
                    </div>
                  </div>
                </a>
              </div>
            </Tilt>
          );
        })}
      </div>
    </section>
  );
};

export default OurWorks;

const tagsComponent = (technologies: string[]) => {
  return (
    <div className="flex gap-x-2 text-white">
      {technologies.map((technology) => {
        return (
          <div
            key={technology}
            className=" border border-primary  p-1 px-2 rounded-md text-sm mt-2"
          >
            <span className=" font-semibold">{technology}</span>
          </div>
        );
      })}
    </div>
  );
};
