import CirclesMoving from "../components/CirclesMoving";

import Hero from "../components/Hero";
import Projects from "../components/Projects";
import Services from "../components/Services";
import Testimonials from "../components/Testimonials";
import { useState, useEffect } from "react";
import { IResource } from "../types/resource.interface";
import { IResponse } from "../types/response.interface";
import { Types } from "../enums/types.enum";
import { useTranslation } from "react-i18next";

function Home() {
  const [videoLink, setVideoLink] = useState<string>("");
  const { i18n } = useTranslation("translation");
  const isEn = i18n.language === "en";
  console.log(isEn);
  //efffects
  useEffect(() => {
    (async () => getVideoLink())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //methods
  const getVideoLink = async () => {
    const path = process.env.REACT_APP_RESOURCES_URL;
    if (!path) return;
    try {
      const response = await fetch(`${path}`);
      if (response.status !== 200)
        throw new Error("Error al obtener los recursos");
      const data = (await response.json()) as IResponse<IResource>;

      const video = data.result.find(
        (item) =>
          item.type.trim().toUpperCase() === Types.HomeTrailer.toUpperCase()
      );
      if (video) setVideoLink(video.path);
    } catch (error) {
      alert("ha ocurrido un error, intente de nuevo mas tarde");
      console.log(error);
    }
  };

  return (
    <div>
      <div className=" z-10 relative">
        <Hero videoLink={videoLink} />
        <Services isEn={isEn} />
        <Projects isEn={isEn} />
        <Testimonials isEn={isEn} />
      </div>
      <CirclesMoving />
    </div>
  );
}

export default Home;
