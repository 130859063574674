import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { AppContext } from "./context/app.context";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Project from "./pages/Project";
import OurWorks from "./pages/OurWorks";
import { I18nextProvider } from "react-i18next";
import "./i18n";
import i18 from "i18next";

function App() {
  const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    { path: "/projects/:type", element: <Project /> },
    { path: "/about", element: <About /> },
    { path: "/contact", element: <Contact /> },
    { path: "/our-works", element: <OurWorks /> },
  ]);
  return (
    <I18nextProvider i18n={i18} defaultNS={"translation"}>
      <AppContext>
        <div className="text-white/80  h-screen relative w-[100vw] overflow-x-hidden ">
          <Header />
          <RouterProvider router={router} />
          <Footer />
        </div>
      </AppContext>
    </I18nextProvider>
  );
}

export default App;
