import React from 'react'

const Title = ({ text, className }: any) => {
    return (
        <div className={`font-semibold relative ${className}`}>
            <span className=' relative z-10 text-3xl'>{text}</span>
        </div>
    )
}

export default Title