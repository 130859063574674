import Illustration2 from "../assets/svg/illustration-2.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IContact {
  name: string;
  email: string;
  message: string;
}

const Contact = () => {
  //state
  const [data, setData] = useState<IContact>({
    name: "",
    email: "",
    message: "",
  } as IContact);
  const [errors, setErrors] = useState<string[]>([]);

  const { t } = useTranslation("translation");

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (validate()) {
      setErrors([]);
      openEmailApp();
    }
  };

  const openEmailApp = () => {
    window.location.href = `mailto:tenseird@gmail.com?subject=Contacto de ${data.name}&body=${data.message}`;
  };

  const validate = () => {
    const errors = [];

    if (data.name.length <= 0) {
      errors.push("Nombre requerido");
    }

    //regex match email
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (data.email.length <= 0 || !emailRegex.test(data.email)) {
      errors.push("Email es invalido");
    }

    if (data.message.length <= 5) {
      errors.push("Message debe contener mas de 5 caracteres");
    }

    setErrors(errors);
    return errors.length === 0;
  };

  return (
    <div className=" h-screen text-white containerr mt-20  gap-y-6">
      <div className="text-center items-center flex flex-col mb-10 ">
        <h1 className=" max-w-lg text-center">{t("pages.contact.title")}</h1>
        <p className=" max-w-[80ch] mt-4">{t("pages.contact.body")}</p>
      </div>
      <div className="grid grid-cols-5 items-center gap-8 w-full justify-between">
        <div className="col-span-2 hidden md:flex">
          <img className="w-full" src={Illustration2} alt="" />
        </div>
        <div className="col-span-5 md:col-span-3">
          <form action="">
            <input
              className="w-full mt-6 input"
              type="text"
              name="name"
              placeholder={t("pages.contact.input1") || ""}
              onChange={handleChange}
            />
            <input
              className="w-full mt-6 input"
              type="email"
              name="email"
              onChange={handleChange}
              placeholder={t("pages.contact.input2") || ""}
            />
            <textarea
              className="w-full mt-6 input"
              name="message"
              id=""
              onChange={handleChange}
              cols={30}
              rows={10}
              placeholder={t("pages.contact.input3") ?? ""}
            ></textarea>

            {errors.map((error) => (
              <span key={error} style={{ color: "white" }}>
                {error} <br />
              </span>
            ))}
            <button
              type="button"
              onClick={handleSubmit}
              className="w-full mt-6 btn"
            >
              {t("send")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
