import React, { useEffect, useState } from "react";
import Logo from "../assets/png/logo.png";
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import i18 from "i18next";

const Header = () => {
  //hooks
  const { t } = useTranslation("translation");

  const nav = [
    { name: t("menu.home"), link: "/" },
    { name: t("menu.ourWork"), link: "/our-works" },
    { name: t("menu.aboutUs"), link: "/about" },
    { name: t("menu.contact"), link: "/contact" },
  ];

  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [langOpen, setLangOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleDropdown = () => {
    setLangOpen(!langOpen);
  };

  const handleClickLang = (language: string) => {
    i18.changeLanguage(language);
  };

  return (
    <header
      className={`${
        isScrolled ? "bg-red-500" : "bg-dark"
      } z-50 sticky top-0 px-10 h-[80px] flex justify-between items-center w-full text-white/80`}
    >
      <img
        className="w-40"
        src={Logo}
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.location.href = "/";
        }}
        alt=""
      />
      <nav className="hidden md:flex">
        <ul className="flex gap-x-5 ">
          {nav.map((item) => (
            <li className=" hover:text-pink cursor-pointer" key={item.link}>
              <a href={item.link}>{item.name}</a>
            </li>
          ))}
        </ul>
        <div className="relative">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={toggleDropdown}
          >
            {t("menu.lang")}
          </button>
          {langOpen && (
            <ul className="absolute bg-white text-gray-700 py-2 w-48 shadow-lg">
              <li>
                <button
                  onClick={() => handleClickLang("en")}
                  className="block px-4 py-2 hover:bg-gray-200"
                >
                  En
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleClickLang("es")}
                  className="block px-4 py-2 hover:bg-gray-200"
                >
                  Es
                </button>
              </li>
            </ul>
          )}
        </div>
      </nav>
      <div
        onClick={() => setMenuOpen(!menuOpen)}
        className="flex md:hidden text-2xl"
      >
        {menuOpen ? (
          <IoClose className="text-3xl" />
        ) : (
          <FiMenu className="text-3xl" />
        )}
      </div>
      <MenuMobile menuOpen={menuOpen} nav={nav} />
    </header>
  );
};

export default Header;

const MenuMobile = ({ nav, menuOpen }: any) => {
  return (
    <nav
      className={`${
        menuOpen ? "h-[70vh] " : "h-[0vh] p-0 "
      } duration-700 transition-all md:hidden absolute inset-0 bg-dark z-50   top-[80px] overflow-hidden`}
    >
      <ul className="flex flex-col gap-x-5 items-center">
        {nav.map((item: any, index: any) => (
          <li className=" hover:text-pink cursor-pointer p-10" key={index}>
            <a href={item.link}>{item.name}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
};
